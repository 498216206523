@use '@angular/material' as mat;
@import "pallete";

$light-gray: #f6f6f6;
$padding-small-horizontal: 0.5em;
$padding-small-vertical: 0.5em;
$padding-large-horizontal: 1em;
$padding-large-vertical: 1em;
$muted-color: #777;
$light-color: #ccc;
$default-link-color: darken(mat.get-color-from-palette(mat.define-palette($mat-accent)), 5%);

.inner-l, .inner-lr, .inner-all {
  padding-left: $padding-small-horizontal;
}

.inner-r, .inner-lr, .inner-all {
  padding-right: $padding-small-horizontal;
}

.inner-t, .inner-tb, .inner-all {
  padding-top: $padding-small-vertical;
}

.inner-b, .inner-tb, .inner-all {
  padding-bottom: $padding-small-vertical;
}

.inner-s-l, .inner-s-lr, .inner-s-all {
  padding-left: calc($padding-small-horizontal / 2);
}

.inner-s-r, .inner-s-lr, .inner-s-all {
  padding-right: calc($padding-small-horizontal / 2);
}

.inner-s-t, .inner-s-tb, .inner-s-all {
  padding-top: calc($padding-small-vertical / 4);
}

.inner-s-b, .inner-s-tb, .inner-s-all {
  padding-bottom: calc($padding-small-vertical / 4);
}

.outter-l, .outter-lr, .outter-all {
  margin-left: $padding-large-horizontal;
}

.outter-r, .outter-lr, .outter-all {
  margin-right: $padding-large-horizontal;
}

.outter-t, .outter-tb, .outter-all {
  margin-top: $padding-large-vertical;
}

.outter-b, .outter-tb, .outter-all {
  margin-bottom: $padding-large-vertical;
}

.monospace {
  font-family: monospace;
}

.cp-text-muted {
  color: $muted-color;

  * {
    color: $muted-color;
  }
}

.cp-text-light {
  color: $light-color;

  * {
    color: $light-color;
  }
}

.cp-text-large {
  font-size: 2em;
}

.cp-text-emphasis {
  font-size: 1.2em;
}

.cp-text-medium {
  font-size: 1.5em;
}

.cp-text-strike {
  text-decoration: line-through;

  * {
    text-decoration: line-through;
  }
}

.cp-text-right {
  text-align: right;
}

.cp-text-small {
  font-size: 0.8em;
}

.cp-text-smaller {
  font-size: 0.6em;
}

.cp-filler {
  flex: 1 1 auto;
}

.cp-text-strong {
  font-weight: bold !important;
}

.cp-text-italic {
  font-style: italic;
}

.cp-icon-small {
  font-size: 1em;
}

.cp-form-container {
  display: flex;
}

.cp-form-container > * {
  width: 100%;
  margin: 0 10px;
}

.cp-text-success {
  color: darkgreen;

  * {
    color: darkgreen;
  }
}

.cp-text-warn {
  color: orangered;

  * {
    color: orangered;
  }
}

.cp-text-info {
  color: royalblue;

  * {
    color: royalblue;
  }
}

.cp-text-danger {
  color: red !important;

  * {
    color: red !important;
  }
}

.cp-text-danger-light {
  color: lighten(orangered, 20%) !important;

  * {
    color: lighten(orangered, 20%) !important;
  }
}

@media all, (max-width: 599px) {
  .app-fill-viewport {
    height: calc(100vh - 56px);
  }
}

@media all, (min-width: 600px) {
  .app-fill-viewport {
    height: calc(100vh - 64px);
  }
}

.cp-link {
  color: $default-link-color;
  text-decoration: none;
}

.cp-link-clean {
  text-decoration: none;
}

.cp-link-primary {
  color: mat.get-color-from-palette(mat.define-palette($mat-primary));
  text-decoration: none;
}

.cp-link-accent {
  color: mat.get-color-from-palette(mat.define-palette($mat-accent));
  text-decoration: none;
}

.cp-link-warn {
  color: orangered;
  text-decoration: none;
}

.cp-link-white {
  color: white;
  text-decoration: none;
}

.flex-centered {
  display: flex;
  align-items: center;
}

mat-list-item.selected, tr.selected {
  background-color: lighten(mat.get-color-from-palette(mat.define-palette($mat-primary)), 30%);

  * {
    color: white;
  }
}

table.default-table {
  background-color: mat.get-color-from-palette(mat.define-palette($mat-primary));
  border-radius: 0.3em;

  th, td {
    padding: 0.2em 0.5em;
  }

  th {
    color: white;
  }

  td {
    border-radius: 0.2em;
    background-color: lighten(mat.get-color-from-palette(mat.define-palette($mat-primary)), 55%);
  }
}

.round-corners-5 {
  border-radius: 5px;
}

@media print {
  .dont-print {
    display: none;
  }
  div {
    page-break-inside: avoid;
  }
}

.content-descricao p {
  //background-color: #dfdfdf;
  margin: 0 !important;
  padding: 0 !important;
}

hr {
  border: none;
  padding: 0;
  margin: 0.7em 0 0.5em 0;
  border-bottom: 1px solid #ccc;
}

p {
  margin: 0 !important;
}

.cp-fieldset {
  border: 1px solid lighten(mat.get-color-from-palette(mat.define-palette($mat-primary)), 55%);
  border-radius: 1em;

  legend {
    //margin-left: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-weight: bold;
  }
}

.bg-light-gray {
  background-color: $light-gray;
}

.bg-lighter-gray {
  background-color: lighten(black, 99%);
}

.mat-header-row {
  background-color: $light-gray !important;
}

.divider {
  opacity: 0.8;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.template-variable {
  padding: 0.2em 0.5em;
  border-radius: 0.25em;
  background-color: #4d4d4d;
  color: white;
}

.template-macro {
  font-family: monospace;
  font-weight: bolder;
  color: #444;
}