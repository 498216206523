//@import "assets/fonts/material-icons.css";
//@import "assets/fonts/roboto.css";
@import "@fontsource/material-icons/index.css";
@import "@fortawesome/fontawesome-free/css/all.css";

@import "common";
@import "theme";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
